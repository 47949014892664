import React, {
  useState, useEffect, createContext, useContext,
} from 'react';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from '@/contexts/auth-context';
import LoadingScreen from '@/components/loading-screen';
import getEnvVar from '@/utilities/environment-variable';
import { useAppAuth } from '@/utilities/authorisation';
import updatePageIcons from '@/contexts/admin-context/update-page-icons';
import { NAV_VERSION } from '@/modules/page-customisation/utilities';
import countries from '../../../pages/api/content/countries.json';

export const AdminContext = createContext();

const AdminContextProvider = ({ children }) => {
  const adminOverride = getEnvVar('REACT_APP_IS_ADMIN');
  const adminOverrideValue = adminOverride ? adminOverride === 'true' || adminOverride === 'True' : null;
  const carrierOverrideValue = getEnvVar('REACT_APP_CARRIER');
  const { getAccessToken, getTokenType, setReturnsProduct } = useContext(AuthContext);
  const [config, setConfig] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [carrier, setCarrier] = useState(carrierOverrideValue);
  const [isAdmin, setIsAdmin] = useState(adminOverrideValue);
  const [rootCompanyId, setRootCompanyId] = useState();
  const [rootCompanyConfig, setRootCompanyConfig] = useState();
  const [merchantSubBrandName, setMerchantSubBrandName] = useState(null);
  const [adminModeMerchantId, setAdminModeMerchantId] = useState(null);
  const [adminNotificationEnabled, setAdminNotificationEnabled] = useState(false);
  const [forceNavV2, setForceNavV2] = useState(false);
  const [{ authData }] = useAppAuth();
  const localModeEnabled = getEnvVar('REACT_APP_LOCAL_CONFIG_MODE') ?? null;

  const configPath = localModeEnabled === 'true' ? '/api/local-admin-config' : '/api/admin-config';

  const router = useRouter();

  const getPortalInstance = () => {
    const { pathname } = router;

    const instanceCarrier = getEnvVar('REACT_APP_CARRIER');

    return {
      carrier: carrierOverrideValue || instanceCarrier,
      isAdmin: adminOverrideValue || (pathname !== '/404' && !pathname.includes('[companyId]')),
    };
  };

  const getConfig = async () => {
    async function getRemoteConfig() {
      try {
        const response = await axios.get(configPath, {
          params: {
            carrier,
          },
          headers: {
            Authorization: `${authData?.token_type}  ${authData?.access_token}`,
          },
        });
        setConfig(response.data);
        setLoading(false);
      } catch (exception) {
        setLoading(false);
        setError(exception.message);
      }
    }
    if (carrier) {
      getRemoteConfig();
    }
  };

  const getRootConfig = () => {
    async function getRootCompany() {
      try {
        const response = await axios.get(`${getEnvVar('REACT_APP_DODDLE_API_URL')}/v1/companies/${rootCompanyId}`,
          {
            headers: {
              Authorization: `${getTokenType()} ${getAccessToken()}`,
            },
          });
        setRootCompanyConfig(response.data.resources);
        setLoading(false);
      } catch (exception) {
        setLoading(false);
        setError(exception.message);
      }
    }
    if (rootCompanyId) {
      getRootCompany();
    }
  };

  /**
   * Some portals can have sub brand assets enabled on merchant level,
   * checking this here for admin-related unauthenticated pages like Login, Forgot-password, etc.
   */
  const checkForSubBrand = async (merchant) => {
    const makeRequest = async () => {
      setLoading(true);
      const { data } = await axios.post('/api/merchant-brand', {
        params: {
          merchant,
          token: `${authData?.token_type}  ${authData?.access_token}`,
        },
      });
      setMerchantSubBrandName(data?.subBrand);
    };
    try {
      await makeRequest();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getAdminCountries = (countriesObj = {}) => Object.entries(countriesObj)
    .map(([countryCode, isEnabled]) => ({
      code: countryCode,
      label: countries[countryCode],
      isEnabled,
    }))
    .sort((countryA, countryB) => countryA?.label?.localeCompare(countryB?.label));

  useEffect(() => { getRootConfig(); }, [rootCompanyId]);

  useEffect(() => {
    if (carrier && authData) {
      getConfig();
    }
  }, [carrier, authData]);

  useEffect(() => {
    if (config) {
      setReturnsProduct(config?.productName);
    }
  }, [config]);

  useEffect(() => {
    if (router.query.companyId
      && (router.query.companyId !== getEnvVar('REACT_APP_CARRIER'))
      && authData?.token_type
      && config?.features?.shouldCheckForSubBrand) {
      checkForSubBrand(router.query.companyId);
    }
  }, [config, authData?.token_type]);

  useEffect(() => {
    if (router) {
      const portalInstance = getPortalInstance();
      if (portalInstance.carrier) {
        updatePageIcons(portalInstance.carrier.toLowerCase());

        if (!router.asPath?.includes('token')) {
          setCarrier(portalInstance.carrier);
          setIsAdmin(portalInstance.isAdmin);
          Sentry.setTags({ carrier: portalInstance.carrier });
        }
      }
      if (!portalInstance.carrier) {
        setLoading(false);
        setError('No carrier found for this url');
      }
    }
  }, [router]);

  if (loading) return <LoadingScreen />;

  const getAdminFeature = (featureName) => config?.features?.[featureName] ?? {};
  const getCarrierLogo = () => config?.logoUrl;
  const getCarrierAltLogo = () => config?.altLogoUrl;
  const getCarrierSubBrandLogoUrl = () => config?.subBrandLogoUrl ?? null;
  const getCarrierName = () => config?.carrierName;
  const fetchRootCompany = () => {
    if (getCarrierName()) {
      setRootCompanyId(getCarrierName());
    }
  };
  // Determines if the login page should use the external auth provider
  const getLoginWithExternal = () => {
    if (adminOverrideValue) return false; // Automatic login uses normal login page
    return !!config?.auth?.loginWithExternalAuth;
  };
  // Get the auth config object from the config
  const getAuth = () => config?.auth;

  const getAdminConfigValue = (value) => {
    if (typeof config?.[value] !== 'undefined') {
      return config[value];
    }
    return {};
  };

  const getEmailOnlyLogin = () => getAdminConfigValue('auth')?.emailOnly;
  const getValidationException = () => getAdminConfigValue('auth')?.validationException;

  const getAdminProductName = () => getAdminConfigValue('productName');

  const isSubBrandEnabled = () => merchantSubBrandName && merchantSubBrandName === config?.subBrandName;
  const isNavBarV2 = () => (forceNavV2 ? true : (getAdminFeature('navigation')?.version === NAV_VERSION.V2 ?? false));
  const getAdminNavRoute = () => (isNavBarV2() ? getAdminFeature('navigation')?.pages : getAdminFeature('navigation'));

  return (
    <AdminContext.Provider
      value={
        {
          carrier,
          isAdmin,
          adminModeMerchantId,
          isAdminReviewMode: Boolean(adminModeMerchantId),
          adminNotificationEnabled,
          setAdminNotificationEnabled,
          loading,
          error,
          getAdminConfigValue,
          getAdminFeature,
          getCarrierName,
          getCarrierLogo,
          getCarrierAltLogo,
          getCarrierSubBrandLogoUrl,
          fetchRootCompany,
          getSupportedAdminCountries: () => getAdminCountries(rootCompanyConfig?.products?.[getAdminProductName()]?.countriesEnabled),
          getLoginWithExternal,
          getAuth,
          getAdminProductName,
          setAdminModeMerchantId,
          setIsAdmin,
          getEmailOnlyLogin,
          getValidationException,
          rootCompanyConfig,
          getRootConfig,
          isSubBrandEnabled,
          isNavBarV2,
          setForceNavV2,
          getAdminNavRoute,
          ...config,
        }
      }
    >
      {children}
    </AdminContext.Provider>
  );
};

AdminContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminContextProvider;
